import { useRouter } from 'next/router';
import { useAuthContext } from '@/lib/providers/AuthProvider';
import { isUserCustomer, isUserEditor, isUserManager, isUserTranscriptionist } from '@common/types/User';
import {
	MANAGER_RECORDINGS_PATH,
	TRANSCRIPTIONST_FILES_PATH,
	LOGIN_PAGE_PATH,
	CUSTOMER_PROJECTS_PAGE_PATH,
	UNKNOWN_PAGE_PATH,
} from '@/lib/paths';

export default function Home() {
	const { user } = useAuthContext();
	const router = useRouter();

	// sometimes the Home is rendered even though the path isn't '/'
	if (typeof window !== 'undefined' && window.location.pathname !== '/') {
		router.push(window.location.href);
		return null;
	}

	if (!user) {
		router.replace(LOGIN_PAGE_PATH);
	} else {
		const home = isUserManager(user, true)
			? MANAGER_RECORDINGS_PATH
			: isUserTranscriptionist(user) || isUserEditor(user)
			? TRANSCRIPTIONST_FILES_PATH
			: isUserCustomer(user)
			? CUSTOMER_PROJECTS_PAGE_PATH
			: null;

		router.replace(home || UNKNOWN_PAGE_PATH);
	}
	return null;
}
